/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import Loading from '@domui-components/Loading';
import Metadata from '@domui-utils/workflow/metadata';

const TerminationReasonsPage: React.FC = () => {
  const [terminationReasonsData, setTerminationReasonsData] = useState<any>({});

  useEffect(() => {
    async function fetchTerminationReasonsData() {
      const wfService = new Metadata();
      const response = await wfService.getTerminationReasons();
      setTerminationReasonsData(response);
    }
    fetchTerminationReasonsData();
  }, []);

  if (!terminationReasonsData) {
    return <Loading />;
  }

  return (
    <div>
      {!!terminationReasonsData?.length && (
        <div style={{ alignItems: 'center' }} className="max-w-4xl mb-8">
          <h3>Termination Reasons Page</h3>
          <ul>
            {terminationReasonsData.map((item: any) => (
              <div key={item.id}>
                <li className="mb-0 last:mb-4">
                  {item.id} - TERMINATIONREASON: {item.terminationReasonPk}
                  {item.id} - REASON: {item.reason}
                  {item.id} - ORGANIZATION TYPE: {item.organizationType}
                  {item.id} - DO NOT ALLOW USER ENTRY:{' '}
                  {item.donotAllowUserentry}
                  {item.id} - STATUS: {item.status}
                  {item.id} - NF KEY: {item.nfKey}
                </li>
              </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TerminationReasonsPage;
